import { template as template_f03b415264da4e4395dd527da133ce42 } from "@ember/template-compiler";
const SidebarSectionMessage = template_f03b415264da4e4395dd527da133ce42(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
