import { template as template_eae2452d701743288c1fb8b0ffb9488b } from "@ember/template-compiler";
import { hash } from "@ember/helper";
const DropdownItem = template_eae2452d701743288c1fb8b0ffb9488b(`
  <li class="dropdown-menu__item" ...attributes>{{yield}}</li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownDivider = template_eae2452d701743288c1fb8b0ffb9488b(`
  <li ...attributes><hr class="dropdown-menu__divider" /></li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownMenu = template_eae2452d701743288c1fb8b0ffb9488b(`
  <ul class="dropdown-menu" ...attributes>
    {{yield (hash item=DropdownItem divider=DropdownDivider)}}
  </ul>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default DropdownMenu;
