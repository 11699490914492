import { template as template_83f295a2f41a41978e3ca3ba05d987ae } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const TopicCell = template_83f295a2f41a41978e3ca3ba05d987ae(`
  <SortableColumn
    @order="default"
    @category={{@category}}
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name={{@name}}
    @bulkSelectEnabled={{@bulkSelectEnabled}}
    @showBulkToggle={{@showBulkToggle}}
    @canBulkSelect={{@canBulkSelect}}
    @canDoBulkActions={{@canDoBulkActions}}
    @showTopicsAndRepliesToggle={{@showTopicsAndRepliesToggle}}
    @newListSubset={{@newListSubset}}
    @newRepliesCount={{@newRepliesCount}}
    @newTopicsCount={{@newTopicsCount}}
    @bulkSelectHelper={{@bulkSelectHelper}}
    @changeNewListSubset={{@changeNewListSubset}}
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default TopicCell;
