import { template as template_d6aa121e35474e29ac202b20742ec8b3 } from "@ember/template-compiler";
const FKControlMenuContainer = template_d6aa121e35474e29ac202b20742ec8b3(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
