import { template as template_cde19ad1c33c4460a0ab63121b516583 } from "@ember/template-compiler";
const WelcomeHeader = template_cde19ad1c33c4460a0ab63121b516583(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
